import { useEffect, useState } from "react";
import {Context} from "../context";
import {useContext } from "react";
import axios from "axios";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Container, Grid, Typography,  IconButton, InputAdornment, TextField } from '@mui/material';
import {Card, CardContent,CardMedia, }  from '@mui/material';
import { createTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {CssBaseline, List, ListItem, ListItemIcon } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined';
import 'dayjs/locale/ru';
import { useNavigate } from "react-router-dom";
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import DescriptionIcon from '@mui/icons-material/Description';
import DeleteIcon from '@mui/icons-material/Delete';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import GetAppIcon from '@mui/icons-material/GetApp';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import MusicVideoIcon from '@mui/icons-material/MusicVideo';
import PdfViewerModal from "./../components/PDFViewer/PDFViewer";
import MusicPlayer from "./../components/MusicPlayer/musicPlayer";
import ReactAudioPlayer from 'react-audio-player';
import PauseIcon from '@mui/icons-material/Pause';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import DoneIcon from '@mui/icons-material/Done';


const SongItemWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 1rem;
  background-color: white;
`;

const SongTitle = styled('span')`
  font-weight: bold;
  font-size: 1.2em;
  margin-right: 1rem;
`;

const ArtistInfo = styled('div')`
  display: flex;
  align-items: center;
`;

const ArtistName = styled('span')`
  margin-left: 0.5rem;
  font-size: 0.9em;
  color: gray;
`;



const theme = createTheme();

theme.typography.h3 = {
  fontSize: '1.2rem',
  '@media (min-width:600px)': {
    fontSize: '1.5rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2rem',
  },
};

const Back = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));


  
const SostavPage = (props) => {
    const navigate = useNavigate();
    const contextValue=useContext(Context)
    const [sostav, setSostav] =useState([])
    const [results, setResults] =useState([])
    const [razryad_types, setRazryadTypes] =useState([])
    const [composition_times, setCompositionTimes] =useState([])
    const [compositions, setCompositions] =useState([])
    const [selectedRazryad,setSelectedRazryad]=useState(null)
    const [values,setValues]=useState(
        {
            composition_id:'',
            composition_type_id:'',
            sostav_id:contextValue.sostav,
            razryad_type_id:'',
            discription_link:'',
            music_link:''
        })

    async function getRazryadTypes() {      
        let response = await axios.get(`/api/razryads/get_razryad_types_for_order_not_individ`);
        let razryad_types = response.data;// Don't need await here
        setRazryadTypes(razryad_types);
        // console.log("типы разрядов ", {razryad_types})
    };

    async function getCompositionTimes() {      
        let response = await axios.get(`/api/razryads/composition_time`);
        let composition_tymes = response.data;// Don't need await here
        setCompositionTimes(composition_tymes);
        // console.log("типы разрядов ", {razryad_types})
    };
    async function getCompositions() {      
        let response = await axios.get(`/api/compositions/getCompositions/${contextValue.sostav}`);
        let compositions = response.data;// Don't need await here
        setCompositions(compositions);
        // console.log("типы разрядов ", {razryad_types})
    };
    
      async function getSostav(sostav_id)
      {
        if(sostav_id!==null)
        await axios.get(`/api/sostavs/sostav/${sostav_id}`)
            .then(res=>{
                console.log(res.data)
                setSostav(res.data)
            }).catch(err =>alert(err))
            else setSostav([])
      }

      async function getResults(sostav)
      {
        if(sostav!==null)
        await axios.get(`/api/results/get_sostav_results/${sostav}/${contextValue.name}/${contextValue.school.school_name}"`)
            .then(res=>{
                console.log(res.data)
                setResults(res.data)
            }).catch(err =>alert(err))
            else setResults([])
      }
      const [songs,setSongs]=useState([])

      async function getSongs()
      {
        await axios.get(`/api/compositions/get-my-music/${contextValue.school_id}`)
            .then(res=>{
                console.log(res.data)
                setSongs(res.data)
            }).catch(err =>alert(err))
      }

     useEffect(()=>{
        if(contextValue.sostav!==null){
                getSostav(contextValue.sostav)
                getResults(contextValue.sostav)
                getSongs()                
                getRazryadTypes()
                getCompositionTimes()
                getCompositions()
        }
     },[props.active,contextValue.sostav])

     useEffect(()=>
        {
        console.log(selectedRazryad)
        setValues({...values,razryad_type_id:selectedRazryad})
        },[selectedRazryad])
    
    
   
      const [open, setOpen] = useState(false);

    

      function GoToChampionst (result)
        {
          contextValue.setChampionatID(result.championat_id);
          localStorage.setItem('championatID', JSON.stringify(result.championat_id))
          localStorage.setItem('championat_type_name', JSON.stringify(result.championat_type_name))
          localStorage.setItem('championat_type_id', JSON.stringify(result.championat_type_id))
          localStorage.setItem('championat_style_name', JSON.stringify(result.championat_style_name))
          localStorage.setItem('championat_style_id', JSON.stringify(result.championat_style_id))
          localStorage.setItem('championat_name', JSON.stringify(result.championat_name))
          localStorage.setItem('championat_start_date', JSON.stringify(result.championat_start_date))
          localStorage.setItem('championat_end_date', JSON.stringify(result.championat_end_date))
          localStorage.setItem('championat_adress', JSON.stringify(result.championat_adress))
          localStorage.setItem('school_name', JSON.stringify(result.school_name))
          localStorage.setItem('user_id', JSON.stringify(result.user_id))
          localStorage.setItem('championat_final_date_order', JSON.stringify(result.championat_final_date_order))
          localStorage.setItem('championat_phone', JSON.stringify(result.championat_phone))
          navigate("/championat")
        }
        const handleDownloadClick = async (composition, file_link, type) => {
          try {
            const ext=file_link.split('.').pop()
            const response = await axios.get(`/api/compositions/download/${type}/${type==='discription'?composition.composition_id:composition.composition_music_unique_name}/${ext}`, {
              responseType: 'blob',
              headers: { 'Accept': 'application/octet-stream' },
            });
            const file_name=`${type}_${sostav.map(s => s.FIO).join('_')}_${composition.composition_type_name}.${ext}`
            if (response.status === 200) {
              let contentDisposition = response.headers['content-disposition'];
              if (!contentDisposition) {
                // Формируем новое имя файла с учетом типа, состава и расширения
                contentDisposition = `attachment; filename=${file_name}`;
              }
        
              const matches = contentDisposition.match(/filename="(.+)"/);
              const suggestedFileName = matches ? matches[1] : file_name;
              // console.log(suggestedFileName)
              const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
              console.log(url)
              const link = document.createElement('a');
              link.href = url;
              link.download = file_name;
              link.click();
            } else {
              throw new Error('Не удалось скачать файл');
            }
          } catch (error) {
            console.error('Ошибка при скачивании файла:', error);
          }
        };

      const handleDiscriptionUpload = async (event,composition) => {
        // Получаем выбранный файл
        const file = event.target.files[0];
        console.log(file)
        console.log(composition)
        // Отправляем файл на сервер
        try {
          const formData = new FormData();
          formData.append('file', file);
          
          const response = await axios.post(`/api/compositions/upload-discription/${composition.composition_id}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
          
          console.log('Описание успешно загружено: '+response.data.discription_link);
          const index = compositions.findIndex(c => c.composition_id === composition.composition_id);

          if (index !== -1) {
            // Создаём новый массив с изменённым объектом
            const updatedCompositions = [...compositions];
            updatedCompositions[index] = {...updatedCompositions[index], discription_link: response.data.discription_link};
            
            // Обновляем состояние
            setCompositions(updatedCompositions);
          } else {
            console.error(`Не найдена композиция с id ${composition.composition_id}`);
          }
        } catch (error) {
          console.error('Ошибка при загрузке файла:', error);
        }
      };

      const handleUploadMusic = async (event,composition) => {
        // Получаем выбранный файл
        const file = event.target.files[0];
        console.log(file)
        console.log(composition)
        // Отправляем файл на сервер
        try {
          const formData = new FormData();
          formData.append('file', file);
          
          const response = await axios.post(`/api/compositions/upload-music/${composition.composition_id}/${contextValue.school_id}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
          
          console.log('Музыка успешно загружено: '+response.data.composition_music_id);
          const index = compositions.findIndex(c => c.composition_id === composition.composition_id);

          setSongs([...songs,
            {
              composition_music_id:response.data.composition_music_id,
              composition_music_original_name: response.data.composition_music_original_name,
              composition_music_unique_name:response.data.composition_music_unique_name,
              school_id:contextValue.school_id,
              school_name:contextValue.school_name
            }])

          if (index !== -1) {
            // Создаём новый массив с изменённым объектом
            const updatedCompositions = [...compositions];
            updatedCompositions[index] = {...updatedCompositions[index], composition_music_id: response.data.composition_music_id, composition_music_original_name: response.data.composition_music_original_name, composition_music_unique_name:response.data.composition_music_unique_name};
            
            // Обновляем состояние
            setCompositions(updatedCompositions);
          } else {
            console.error(`Не найдена композиция с id ${composition.composition_id}`);
          }
        } catch (error) {
          console.error('Ошибка при загрузке файла:', error);
        }
      };


      const handleSubmit = () => {
        const filteredTimes = composition_times.filter(x => x.razryad_id === selectedRazryad);
        
        // Создаем новый массив объектов на основе отфильтрованных данных
        const promises = filteredTimes.map((item) =>
            axios.post('/api/compositions/add', {
              composition_id: '',
              composition_type_id: item.composition_type_id,
              sostav_id: contextValue.sostav,
              razryad_type_id: selectedRazryad
            }).then((res) => {
              if (res.data.Status === 'Композиция добавлена') {
                return {
                  composition_id: res.data.insertId,
                  composition_type_id: item.composition_type_id,
                  composition_type_name: item.composition_type_name,
                  sostav_id: contextValue.sostav,
                  razryad_type_id: selectedRazryad,
                  razryad_short_name: razryad_types.find(
                    (x) => x.razryad_id === selectedRazryad
                  ).razryad_short_name,
                  discription_link: null,
                  composition_music_id: null
                };
              } else {
                throw new Error(res.data.Error); // Ошибка при добавлении композиции
              }
            })
          );          
          Promise.all(promises).then((results) => {
            // Обновляем состояние
            setCompositions([...compositions, ...results]);
          }).catch((err) => {
            alert(err.message);
          });

        }
        useEffect(()=>
        {
            console.log(compositions)
        },[compositions])


      const menuItems = [
        { text: 'Состав', icon: <AccountCircleIcon /> },
        { text: 'Композиции', icon: <AccountBoxOutlinedIcon /> },
        { text: 'Результаты', icon: <ViewListOutlinedIcon /> }
    ];

    const handleDeleteDiscription = (composition) => {
      axios.post(`/api/compositions/delete-discription/${composition.composition_id}`)
        .then((res) => {
          if (res.data.Status === 'Описание удалено') {
            // Обновляем массив compositions так, чтобы у нужной композиции было установлено discription_link = null
            const updatedCompositions = compositions.map(comp => {
              if (comp.composition_id === composition.composition_id) {
                return { ...comp, discription_link: null };
              }
              return comp;
            });
            
            // Устанавливаем новое значение для состояния compositions
            setCompositions(updatedCompositions);
          }
        })
        .catch((err) => {
          alert(err.message);
        });
    };

    const handleDeleteMusic = (composition) => {
      axios.post(`/api/compositions/delete-music/${composition.composition_id}`)
        .then((res) => {
          if (res.data.Status === 'Музыка удалена') {
            // Обновляем массив compositions так, чтобы у нужной композиции было установлено discription_link = null
            const updatedCompositions = compositions.map(comp => {
              if (comp.composition_id === composition.composition_id) {
                return { ...comp, composition_music_id: null, composition_music_original_name:null, composition_music_unique_name:null  };
              }
              return comp;
            });
            
            // Устанавливаем новое значение для состояния compositions
            setCompositions(updatedCompositions);
          }
        })
        .catch((err) => {
          alert(err.message);
        });
    };

    const handleDeleteRazryad = async (razryad_name) => {
      try {
        // Находим id разряда по его имени
        const razryadId = razryad_types.find(r => r.razryad_short_name === razryad_name)?.razryad_id;
    
        if (!razryadId) {
          throw new Error(`Разряд с именем "${razryad_name}" не найден.`);
        }
    
        // Находим все композиции, относящиеся к данному разряду
        const compositionsToDelete = compositions.filter(c => c.razryad_type_id === razryadId);
    
        // Массив для хранения id удаленных композиций
        const deletedCompositionIds = [];
    
        // Проходимся по композициям и удаляем каждую
        for (const composition of compositionsToDelete) {
          await axios.post(`/api/compositions/delete-composition/${composition.composition_id}`);
          deletedCompositionIds.push(composition.composition_id);
        }
    
        // Удаляем записи из базы данных по составленному списку id
        await axios.post(`/api/compositions/bulk-delete/${razryadId}/${contextValue.sostav}`);
    
        // Обновляем массив compositions, удаляя композиции, которые были удалены
        const updatedCompositions = compositions.filter(c => !deletedCompositionIds.includes(c.composition_id));
        setCompositions(updatedCompositions);
    
        console.log('Композиции успешно удалены.');
      } catch (error) {
        console.error(error.message);
        alert(error.message);
      }
    };

    const handleImageError = (event) => {
        event.target.src = '/uploads/users/no_avatar.jpg'; // Указываем путь к резервному изображению
      };
    const renderContent = () => {
        switch (selectedItem) {
            case 'Состав':
                return  <form onSubmit="return false">           
            <Box
            fullWidth
                sx={{
                    padding:"3px",
                    display: 'flex',                    
                    gap: 1,
                    justifyContent:'center'             
                }}
                >
            
            {sostav.map((s,i)=>(                
                <Card sx={{ width: 180, height:350 }}>
                    <CardMedia
                        component="img"
                        height="180"
                        image={`/uploads/sportsmens/${s.sportsmen_id}/avatar_crop.png?t=${new Date().getTime()}`}
                        onError={handleImageError}
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h6" whiteSpace={'pre'} component="div">
                        {s.FIO}
                        </Typography>
                        <Typography variant="body2" whiteSpace={'pre'} align={'left'}  color="text.secondary">
                        <u>Дата рождения:</u> {s.sportsmen_age}
                        </Typography>
                        <Typography variant="body2" whiteSpace={'pre'} align={'left'}   color="text.secondary">
                        <u>Разряд:</u> {s.razryad_name?s.razryad_name:'б/р'}
                        </Typography>
                        <Typography variant="body2" whiteSpace={'pre'} align={'left'}   color="text.secondary">
                        <u>Тренер:</u> {s.treners}
                        </Typography>
                    </CardContent>
                    </Card>
            ))}

                            </Box> 
                </form>
            case 'Композиции':
                return <>
                <Grid container spacing={2}  direction={{xs:"column-reverse",md:"row"}}>
                <Grid item xs={12}>
                 <FormControl sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel id="razryad-type-lable">Разряд</InputLabel>
                        <Select
                            labelId="razryad-type-lable"
                            id="razryad-type-lable"
                            label="Разряд"
                            value={selectedRazryad}
                            onChange={(e)=>setSelectedRazryad(e.target.value)}
                            >
                                {razryad_types.map((r,i)=>
                                    (
                                        <MenuItem value={r?.razryad_id}>{r?.razryad_short_name}</MenuItem>
                                    ))}
                        </Select>
                        <FormHelperText>Выберите разряд</FormHelperText>
                        </FormControl>
                        <Button sx={{my:1}}  variant="contained" onClick={handleSubmit} component="span">
                            Добавить композиции
                        </Button>
                              </Grid>
                <Grid item xs={12}>              
                <TableContainer component={Paper}>
  <Table size="small" aria-label="simple table">
    <TableHead>
      <TableRow>
        <TableCell align="center">Разряд/Вид</TableCell>
        {/* Заголовки таблицы */}
        {Array.from(new Set(compositions?.sort((a,b)=>a.composition_type_id-b.composition_type_id)?.map((x) => x.composition_type_name)))
          .map((typeName) => (
            <TableCell key={typeName} align="center">
              {typeName}
            </TableCell>
          ))}
      </TableRow>
    </TableHead>
    <TableBody>
      {/* Строки таблицы */}
      {Array.from(new Set(compositions?.sort((a,b)=>a.razryad_type_id-b.razryad_type_id).map((x) => x.razryad_short_name))) 
        .map((shortName) => (
          <TableRow key={shortName}>
            <TableCell align="center">
              <strong>{shortName}</strong>
              <Tooltip title="Удалить копмпозиции по разряду">
                                        <IconButton onClick={() => handleDeleteRazryad(shortName)} color="error"> {/* Здесь должно быть обработчик удаления */}
                                          <DeleteIcon />
                                        </IconButton>
                                      </Tooltip>
            </TableCell>
            {/* Ячейки для каждого типа */}
            {Array.from(new Set(compositions?.map((x) => x.composition_type_id)))
                .sort()
                .map((x) =>  {
                // Найти объект, соответствующий типу и разряду
                const matchingObject = compositions.find(
                  (obj) =>
                    obj.razryad_short_name === shortName &&
                    obj.composition_type_id === x
                );

                if (!matchingObject) {
                  return <TableCell key={`${shortName}-${x}`}></TableCell>; // Если нет объекта, оставляем ячейку пустой
                }
                else
                return (
                    <TableCell key={`${shortName}-${x}`}>
                        <Box display="flex" flexDirection="column" justifyContent="space-between" alignItems="center">
                        <Box sx={{pb:-1, border:'2px dashed red', borderRadius:'5px'}}>
                        <Typography fontSize='10px' align='center'>Описание</Typography>
                          <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                          
                          {!matchingObject.discription_link && 
                              <><input
                                accept="application/pdf"
                                style={{ display: 'none' }}
                                id={`upload-description-${shortName}-${x}`}
                                type="file"
                                onChange={(e) => handleDiscriptionUpload(e, matchingObject)}
                              />
                              <label htmlFor={`upload-description-${shortName}-${x}`}>
                                <Tooltip title="Загрузить описание">
                                  <IconButton color="primary" component="span">
                                    <UploadFileIcon />
                                  </IconButton>
                                </Tooltip>
                              </label>
                            </>}
                            {matchingObject.discription_link && (
                                    <>
                                      {(() => {
                                        const ext = matchingObject.discription_link.split('.').pop().toLowerCase();
                                        if (['pdf', 'jpg', 'jpeg', 'png'].includes(ext)) {
                                          return (
                                            <Tooltip title="Просмотреть описание">
                                              <IconButton onClick={() => handleOpenPDF(matchingObject, matchingObject.discription_link)} color="primary">
                                                <DescriptionIcon />
                                              </IconButton>
                                            </Tooltip>
                                          );
                                        }
                                        return null;
                                      })()}
                                      
                                      <Tooltip title="Скачать описание">
                                        <IconButton onClick={() => handleDownloadClick(matchingObject, matchingObject.discription_link, 'discription')} color="primary">
                                          <GetAppIcon />
                                        </IconButton>
                                      </Tooltip>
                                      
                                      <Tooltip title="Удалить описание">
                                        <IconButton onClick={() => handleDeleteDiscription(matchingObject)} color="error"> {/* Здесь должно быть обработчик удаления */}
                                          <DeleteIcon />
                                        </IconButton>
                                      </Tooltip>
                                    </>
                                  )}
                            </Box>
                            </Box>
                            <Box sx={{mt:1, pb:-1, border:'2px dashed red', borderRadius:'5px'}}>
                            <Typography fontSize='10px' align='center'>Музыка</Typography> 
                          <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" >
                           
                          {!matchingObject.composition_music_id && <>
                            
                                <Tooltip title="Выбрать музыку">
                                  <IconButton color="secondary" component="span">
                                    <MusicVideoIcon onClick={()=>{setSelectMusic(matchingObject);setOpenSelectMusic(true)}}/>
                                  </IconButton>
                                </Tooltip>
                              </>
                            }
                            {matchingObject.composition_music_id && <>
                              <Tooltip title="Воспроизвести музыку">
                                <IconButton onClick={() => handleOpenMusic(matchingObject.composition_music_original_name,matchingObject.composition_music_unique_name)} color="secondary">
                                  <PlayArrowIcon />
                                </IconButton>
                              </Tooltip>
                            <Tooltip title="Скачать музыку">
                                  <IconButton onClick={() => handleDownloadClick(matchingObject, matchingObject.composition_music_unique_name, 'music')} color="secondary">
                                    <GetAppIcon />
                                  </IconButton>
                                </Tooltip>
                              <Tooltip title="Удалить музыку">
                                <IconButton onClick={() => handleDeleteMusic(matchingObject)} color="error">
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </>}
                          </Box>
                          </Box>
                        </Box>
                      </TableCell>
                );
              })}
          </TableRow>
        ))}
    </TableBody>
  </Table>
</TableContainer>                  
                </Grid>
              </Grid>              
            </>
            
           
                    case 'Результаты':                        
                        return <>
                        <TableContainer sx={{  height: "300px", overflow: "auto"}} component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                        <TableRow>
                            <TableCell>№</TableCell>            
                            <TableCell align="center">Соревнование</TableCell>
                            <TableCell align="center">Результат</TableCell>
                            <TableCell align="center">Место</TableCell> 
                        </TableRow>
                        </TableHead>
                    <TableBody>  
                    {
            results?.map((r,i)=>(
              <TableRow
              key={r.razryad_id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                    <TableCell wrap align="center">{i+1}</TableCell>
                    <TableCell wrap align="center"><Button onClick={()=>GoToChampionst(r)}>{r.championat_name}</Button></TableCell>
                    <TableCell wrap align="center">{r.full_score}</TableCell>
                    <TableCell wrap align="center">
                        {`${r.place}`}
                        </TableCell> 
                    </TableRow>))}
                    </TableBody>
                    </Table>
                    </TableContainer>
                        </>
            default:
                return <Typography variant="h6">Выберите элемент из меню</Typography>;
        }
    };
    const [selectedItem, setSelectedItem] = useState('Состав');

    const handleListItemClick = (item) => {
        setSelectedItem(item);
    };
    const list = () => (
        <List>
            {menuItems.map((item, index) => (
                <ListItem button key={item.text} onClick={() => handleListItemClick(item.text)}>
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <Typography variant="body1" sx={{ display: { xs: 'none', md: 'block' } }}>
                        {item.text}
                    </Typography>
                </ListItem>
            ))}
        </List>
    );
    const [openPdf, setOpenPdf] = useState(false);
    const [openPdfLink, setOpenPdfLink] = useState('');
    const [composition_id, setCompositionID] = useState(null);
    const [type, setType] = useState('');

    const [openMusic, setOpenMusic] = useState(false);
    const [openSelectMusic, setOpenSelectMusic] = useState(false);
    const [select_music, setSelectMusic] = useState(false);
    const [music_display_name, setMusicDisplayName] = useState(null);
    const [music_file_name, setMusicFileName] = useState(null);

    const handleOpenPDF = (selectComposition,fileLink) => {
      setOpenPdfLink(fileLink);
      setType('discription')
      setCompositionID(selectComposition.composition_id)
      setOpenPdf(true)
  };
  const handleClosePDF = () => {
    setOpenPdfLink('');
    setOpenPdf(false)
    setType('')
    setCompositionID(null)
};

const handleOpenMusic = (displayName,fileName) => {
  setMusicDisplayName(displayName);
  setMusicFileName(fileName);
  setOpenMusic(true)
};

const handleCloseMusic = () => {
  setMusicDisplayName('');
  setMusicFileName('');
  setOpenMusic(false)
};
const [musicPath, setMusicPath] = useState('');
   
   useEffect(()=>{
    music_file_name&&setMusicPath(`/uploads/musics/${music_file_name}`)},[music_file_name])

useEffect(()=>{console.log(openMusic)},[openMusic])
const [searchTerm, setSearchTerm] = useState('');
const filteredSongs = songs.filter(song => song.composition_music_original_name.includes(searchTerm)).map(song => ({ ...song }));

function SongItem({ song }) {
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayPause = (file_name) => {
      setMusicFileName(file_name);
    }

  const [selected, setSelected] = useState(false);
  const handleSelect = (composition,song) => {
      axios.post(`/api/compositions/change-music/${composition.composition_id}/${song.composition_music_id}`)
        .then((res) => {
          if (res.data.Status === 'Музыка изменена') {
            // Обновляем массив compositions так, чтобы у нужной композиции было установлено discription_link = null
            const updatedCompositions = compositions.map(comp => {
              if (comp.composition_id === composition.composition_id) {
                return { ...comp, composition_music_id: song.composition_music_id, composition_music_original_name:song.composition_music_original_name, composition_music_unique_name:song.composition_music_unique_name  };
              }
              return comp;
            });
            
            // Устанавливаем новое значение для состояния compositions
            setCompositions(updatedCompositions);
          }
        })
        .catch((err) => {
          alert(err.message);
        });
  };

  return (
    <SongItemWrapper>
      <SongTitle>{song.composition_music_original_name}</SongTitle>
      <ArtistInfo>
        <IconButton edge="start" color="primary" onClick={()=>handlePlayPause}>
          {music_file_name!==song.composition_music_unique_name ? <PauseIcon /> : <PlayArrowIcon />}
        </IconButton>
        <Tooltip title="Select">
          <IconButton edge="end" color="primary" onClick={()=>handleSelect(select_music,song)}>
            {song.composition_music_id===select_music.composition_music_id?<TaskAltIcon />:<DoneIcon />}
          </IconButton>
        </Tooltip>
      </ArtistInfo>
      {/* <ReactAudioPlayer src={song.src} autoPlay={isPlaying} controls /> */}
    </SongItemWrapper>
  );
};

    return (       
        <Back sx={{width:{xs:"400",md:"900"}}}>     
        <PdfViewerModal open={openPdf} onClose={handleClosePDF} extension={openPdfLink.split('.').pop()} type={type} id={composition_id}/>   
        {/* {music_name&&<MusicPlayer open={openMusic} onClose={handleCloseMusic} musicName={music_name}/>}   */}
        <Grid container columns={12}>
            <Grid item xs={2} md={4} sx={{borderRight: "0.3px solid black"}}>
            <Grid container columns={12}>
            <CssBaseline />
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CssBaseline />
            </Grid>
                <Grid item xs={12}>
                {list()}  
                
                </Grid>
                </Grid>
                </Grid>
            <Grid item xs={10} md={8} sx={{p:1}}>
                {renderContent()}
            </Grid>
            <Grid item xs={openSelectMusic?6:12} sx={{borderTop: "0.3px solid black",display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                {openMusic&&<Typography variant="body1" sx={{my:1}}>{music_display_name}</Typography>}
                </Grid>
                <Grid item xs={openSelectMusic?6:12}  sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                {openMusic&&
                <ReactAudioPlayer
                  src={musicPath}
                  autoPlay
                  controls
                />}
                </Grid>
                {openSelectMusic&&
                <Grid item xs={6} sx={{borderTop: "0.3px solid black",display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Grid container spacing={2}>
                <Grid item xs={10}>
                          <TextField
                  label="Поиск"
                  value={searchTerm}
                  onChange={e => setSearchTerm(e.target.value)}
                  fullWidth                  
                />
                </Grid>
                <Grid item xs={2}>
                <input
                                accept="audio/*"
                                style={{ display: 'none' }}
                                id={`upload-music`}
                                type="file"
                                onChange={(e) => {handleUploadMusic(e,select_music)}}
                              />
                              <label htmlFor={`upload-music`}>
                                <Tooltip title="Загрузить музыку">
                                  <IconButton color="secondary" component="span">
                                    <UploadFileIcon />
                                  </IconButton>
                                </Tooltip>
                              </label>
                
                </Grid>
                {filteredSongs.map((song, index) => (
                  <Grid item key={index} xs={12}>
                    <SongItem song={song} />
                  </Grid>
                ))}
              </Grid>
              </Grid> }
        </Grid> 
        </Back>      
     );
}
 
export default SostavPage;